import AuthService from '../services/AuthService'
import { useEffect } from 'react'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = { month: 'numeric', day: 'numeric', year: 'numeric' }
) => {
    if (!value) return value
    if (value.length === 10) value += "T12:00:00.000Z"
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const dateToLocal = date => {
    const local = new Date(date)
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    return local.toJSON().slice(0, 10)
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => AuthService.isUserAuthenticated()
export const isUserAuthorized = () => AuthService.isAuthorized()
export const getUserData = () =>
    AuthService.restoreUserData() /*JSON.parse(localStorage.getItem('userData'))*/
export const getAccessToken = () =>
    AuthService.getToken() /*JSON.parse(localStorage.getItem('userData'))*/
export const getAdditionalParams = () => AuthService.getAdditionalParams()

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#1575BB1a', // for option hover bg-color
        primary: '#206f39', // for selected option bg-color
        neutral10: '#206f39', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

//Portal Fácil Admin Utils
export function formatFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return `${bytes.toFixed(dp)} ${units[u]}`
}

export function arrayToBlob(base64) {
    try {
        if (!base64) {
            return null
        }

        //Se array vem formatado como base64 retira
        if (base64.startsWith('data:')) {
            base64 = base64.substr(base64.indexOf(',') + 1)
            console.warn(base64)
        }

        const binaryString = window.atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i)
        }

        return new Blob([bytes], { type: 'application/pdf' })
    } catch (e) {
        console.error(e)
    }
}

//#region Formatos
const dataSimples = { month: 'numeric', day: 'numeric', year: 'numeric' }
const horaSimples = { hour: '2-digit', minute: '2-digit' }
const dataHoraSimples = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
}

//#endregion

//#region formatDateToday()
export const formatDateToday = value => {
    if (!value) return value
    const now = new Date()
    const data = new Intl.DateTimeFormat('pt-BR', dataSimples).format(new Date(value))
    const hora = new Intl.DateTimeFormat('pt-BR', horaSimples).format(new Date(value))
    const hoje = new Intl.DateTimeFormat('pt-BR', dataSimples).format(now)
    const ontem = new Intl.DateTimeFormat('pt-BR', dataSimples).format(
        now.setDate(now.getDate() - 1)
    )
    let retorno = new Intl.DateTimeFormat('pt-BR', dataHoraSimples).format(new Date(value))
    //console.warn(`value: ${value} - data: ${data} - hoje: ${hoje} - ontem: ${ontem} - hora: ${hora}`)
    if (data === hoje) {
        retorno = `hoje às ${hora}`
    } else if (data === ontem) {
        retorno = `ontem às ${hora}`
    } else {
        retorno = `${data} às ${hora}`
    }

    return retorno
}

//#endregion

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDateInFull = (
    value,
    formatting = { month: 'long', day: 'numeric', year: 'numeric' }
) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTime = (
    value,
    formatting = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: 'numeric'
    }
) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatTime = (
    value,
    formatting = { hour: '2-digit', minute: '2-digit', second: 'numeric' }
) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatToYear = (value, formatting = { year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export function isNullOrEmpty(value) {
    return value === null || value === undefined || `${value}`.trim() === ''
}

export const stringCapitalize = str => {
    const retorno = str.toString()
    return retorno.length ? retorno.charAt(0).toUpperCase() + retorno.slice(1) : retorno
}

export const isComplexType = obj => Object.keys(obj).length === 0

export const isArray = obj => Array.isArray(obj)

export const urlPatternValidation = url => {
    return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        url
    )
}

export function useRefresh(history, path, resetRoute) {
    let handler

    const refresh = () => {
        history.push(resetRoute)
        handler = setTimeout(() => history.push(path), 10)
    }

    useEffect(() => {
        return () => handler && clearTimeout(handler)
    }, [handler])

    return refresh
}

export function booleanOptions() {
    const retorno = [
        { key: '', value: '', label: 'Todos' },
        { key: 'true', value: 'true', label: 'Sim' },
        { key: 'false', value: 'false', label: 'Não' }
    ]
    return retorno
}

export function parseBool(str) {
    console.log(`parseBool: ${typeof str}`)
    // strict: JSON.parse(str)

    if (str === null) {
        return false
    }

    if (typeof str === 'boolean') {
        return str === true
    }

    if (typeof str === 'string') {
        if (str === '') {
            return false
        }

        str = str.replace(/^\s+|\s+$/g, '')
        if (str.toLowerCase() === 'true' || str.toLowerCase() === 'yes') {
            return true
        }

        str = str.replace(/,/g, '.')
        str = str.replace(/^\s*\-\s*/g, '-')
    }

    // var isNum = string.match(/^[0-9]+$/) != null
    // var isNum = /^\d+$/.test(str)
    if (!isNaN(str)) {
        return parseFloat(str) !== 0
    }

    return false
}

export function randColor() {
    return `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')
        .toUpperCase()}`
}

export function padLeft(value, totalWidth, paddingChar) {
    const tam = value.toString().length
    // return
    if (tam >= totalWidth) {
        return value
    }
    value = !value ? '0' : value
    const length = totalWidth - value.toString().length + 1
    return Array(length).join(paddingChar || '0') + value
}

export const noop = () => {}

export function getDescricaoAmigavel(desc) {
    if (!desc || typeof desc !== 'string') return ''

    let retorno = desc.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    retorno = retorno
        // .toLowerCase()
        .trim()
        .replace(/(\s)|(\/)/g, '_')
    // .replace('--', '-')
    retorno = retorno.replace(/[^A-Za-z0-9\\-]/g, '')
    return retorno
}
export const formataCpfCnpj = value => {
    const cnpjCpf = value.replace(/\D/g, '')

    if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    }

    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isObject = value => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export const cleanUpObject = obj => {
    if (obj === null || obj === undefined) return obj
    const entries = Object.entries(obj)
        .filter(
            ([_, value]) =>
                value !== null && (typeof value !== 'string' || `${value}`.trim() !== '')
        )
        .map(([key, value]) => {
            const v = isObject(value) ? cleanUpObject(value) : value
            return (v !== null && v !== undefined) ? [key, v] : null
        })
        .filter(x => x !== null)

    return entries?.length > 0 ? Object.fromEntries(entries) : null
}

export const stringToBase64 = value => {
    return window.btoa(value)
}

export const base64ToString = value => {
    return window.atob(value)
}
