import { LogFn, Logger } from '../types/Logger'

import { LOG_LEVEL } from './config/Environment'
import { noop } from '@src/utility/Utils'

export type LogLevel = 'log' | 'warn' | 'error'

const NO_OP: LogFn = (message?: string, ...optionalParams: unknown[]) => noop

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    error: { (...data: any[]): void; (message?: any, ...optionalParams: any[]): void }
    warn: LogFn
    log: LogFn

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {}

        this.error = console.error.bind(console)

        if (level === 'error') {
            this.warn = NO_OP
            this.log = NO_OP

            return
        }

        this.warn = console.warn.bind(console)

        if (level === 'warn') {
            this.log = NO_OP

            return
        }

        this.log = console.log.bind(console)
    }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL })
